// $primary-background:  linear-gradient(5deg, rgba(196,176,4,1) 6%, rgba(50,11,113,1) 56%);
$primary-background:  linear-gradient(5deg, rgba(196,150,4,1) 6%, rgba(11,88,113,1) 56%);

$primary-text: #d2cccc;

// font families
$cinzel: 'Cinzel', serif;
$cinzel-decorative: 'Cinzel Decorative', cursive;
$sedgwick-ave-display: 'Sedgwick Ave Display', cursive;
$edu-sa-beginner: 'Edu SA Beginner', cursive;

$title-font: $edu-sa-beginner;