// import the color.scss
@import './styles/colors';

a {
  text-decoration: none;

  &.active {
    text-decoration: underline;
  }
}

.App {
  width: 100%;
  position: absolute;
  text-align: center;
  background-image: url('https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398236/telxbkucqksj2ebwgnfy.jpg');
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  color: #f8f6f6;
  font-family: 'Sedgwick Ave';
  font-size: 2.2em;

  @media screen and (max-width: 768px) {
    background-position: -200px 0;
    background-attachment: fixed;
  }

  @media (max-width: 588px) {
    font-size: 1.2rem;
  }
}

.App-logo {
  height: 65px;
  position: relative;
  padding: 0 20px;
  top: -18px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  margin: 0.37rem 20px 0.6rem;
}

a {
  color: $primary-text;
}

.navbar {
  width: fit-content;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 10px;

  a {
    color: $primary-text;
    padding: 3px;
    margin: 0 10px;
    text-decoration: none;

    &.active,
    &:hover {
      color: white;
      border-bottom: 1px solid white;
    }
  }

}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.social-footer-icons {
  margin-right: 40px;
  display: flex;
  gap: 6px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    font-size: 30px;
    gap: 20px;
  }

  a {
    color: $primary-text;
  }

  li:last-of-type {
    margin-right: 0;
  }

  .fa {
    font-size: 1.3rem;
    color: white;

    &:hover {
      color: darken(darkgray, 25%);
      transition: color 0.3s ease-in;
    }
  }
}